<template>
  <div
    ref="myOption"
    v-loading="loading"
    :style="{
      width: '100%',
      height: '400px',
      background: '#F9F9F9',
      borderRadius: '14px',
    }"
  ></div>
</template>

<script>
import { permissionByProduct } from "@/api/home/home";
export default {
  name: "viewChartsTwo",
  watch: {
    myOption: {
      handler(newVal, oldVal) {
        if ((newVal, oldVal)) {
          if (newVal) {
            this.$echarts.init(this.$refs.myOption).setOption(newVal);
          } else {
            this.$echarts.init(this.$refs.myOption).setOption(oldVal);
          }
        } else {
          return;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: true,
      myOption: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["总量"],
          x: "right",
          padding: [20, 50, 0, 0],
          icon: "circle",
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: [],
            axisLabel: {
              interval: 0,
              rotate: 30,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "总量",
            type: "bar",
            data: [],
            color: "#87ABFF",
            barWidth: 13,
          },
        ],
      },
    };
  },
  mounted() {
    //this.drawLine();
    this.queryPermissionByProduct();
  },
  methods: {
    drawLine() {
      this.$echarts.init(this.$refs.myOption).setOption(this.myOption);
    },
    queryPermissionByProduct() {
      permissionByProduct().then((res) => {
        if (res.status === 0) {
          this.myOption.xAxis[0].data = res.result.nameList;
          this.myOption.series[0].data = res.result.countList;
          this.loading = false;
        }
        this.drawLine();
      });
    },
  },
};
</script>
