<template>
  <div
    v-loading="loading"
    ref="optionCirclie"
    :style="{
      width: '100%',
      height: '400px',
      background: '#F9F9F9',
      borderRadius: '14px',
    }"
  ></div>
</template>

<script>
import { activationByStatus } from "@/api/home/home";
export default {
  name: "viewChartsThree",
  watch: {
    //观察option的变化
    optionCirclie: {
      handler(newVal, oldVal) {
        if (this.$echarts) {
          if (newVal) {
            this.$echarts.init(this.$refs.optionCirclie).setOption(newVal);
          } else {
            this.$echarts.init(this.$refs.optionCirclie).setOption(oldVal);
          }
        } else {
          // this.init();
        }
      },
      deep: true, //对象内部属性的监听，关键。
    },
  },
  data() {
    return {
      optionCirclie: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "5%",
          left: "center",
          icon: "circle",
          itemGap: 60,
        },
        color: ["#4880FF", "#FF9000", "#C0D2F0"],
        series: [
          {
            type: "pie",
            radius: ["40%", "48%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              // {value: 1500, name: '已激活'},
              // {value: 700, name: '已过期'},
            ],
          },
        ],
      },
      ActivatedNum: "",
      ExpiredNum: "",
      availableNum: "",
      loading: true,
    };
  },
  mounted() {
    //this.drawLine();

    this.queryActivationByStatus();
  },
  methods: {
    drawLine() {
      this.$echarts
        .init(this.$refs.optionCirclie)
        .setOption(this.optionCirclie);
    },
    transferVal() {
      this.$emit(
        "getData",
        this.ActivatedNum,
        this.ExpiredNum,
        this.availableNum
      );
    },
    queryActivationByStatus() {
      activationByStatus().then((res) => {
        if (res.status === 0) {
          this.optionCirclie.series[0].data = res.result;
          this.dataSource = res.result;
          this.ActivatedNum = res.result[0].value;
          this.ExpiredNum = res.result[1].value;
          this.availableNum = res.result[2].value;
          this.loading = false;
          this.transferVal();
        }
      });
      this.drawLine();
    },
  },
};
</script>
