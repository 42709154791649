import request from '@/utils/request'

//统计激活码数量
export function activationByProduct(){
    return request({
        url:Config.url201+'/activationByProduct',
        method:'post',
        data:JSON.stringify({}),
    })
}

//统计权限数量
export function permissionByProduct(){
    return request({
        url:Config.url201+'/permissionByProduct',
        method:'post',
        data:JSON.stringify({}),
    })
}

//统计激活码不同状态分布
export function activationByStatus(){
    return request({
        url:Config.url201+'/activationByStatus',
        method:'post',
        data:JSON.stringify({}),
    })
}

export function getProduct(){
    return request({
        url:Config.product,
        method:'get',
    })
}