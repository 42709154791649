<template>
  <div
    v-loading="loading"
    ref="option"
    :style="{
      width: '100%',
      height: '400px',
      background: '#F9F9F9',
      borderRadius: '14px',
    }"
  ></div>
</template>

<script>
import { activationByProduct } from "@/api/home/home";
export default {
  name: "viewChartsOne",
  //数据自动刷新，必然需要一个监听机制告诉Echarts重新设置数据
  watch: {
    //观察option的变化
    option: {
      handler(newVal, oldVal) {
        if (this.$echarts) {
          if (newVal) {
            this.$echarts.init(this.$refs.option).setOption(newVal);
          } else {
            this.$echarts.init(this.$refs.option).setOption(oldVal);
          }
        } else {
          // this.init();
        }
      },
      deep: true, //对象内部属性的监听，关键。
    },
  },
  data() {
    return {
      loading: true,
      option: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["已激活", "已过期", "可用"],
          x: "right",
          padding: [20, 50, 0, 0],
          icon: "circle",
        },
        calculable: true,

        xAxis: [
          {
            type: "category",
            data: [],
            axisLabel: {
              interval: 0,
              rotate: 30,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "已激活",
            type: "bar",
            data: [],
            color: "#4049FF",
            barWidth: 13,
          },
          {
            name: "已过期",
            type: "bar",
            data: [],
            color: "#FF9000",
            barWidth: 13,
          },
          {
            name: "可用",
            type: "bar",
            data: [],
            color: "#87ABFF",
            barWidth: 13,
          },
        ],
      },
    };
  },
  mounted() {
    this.queryActivationByProduct();
  },
  methods: {
    drawLine() {
      this.$echarts.init(this.$refs.option).setOption(this.option);
    },
    queryActivationByProduct() {
      activationByProduct().then((res) => {
        if (res.status === 0) {
          this.option.xAxis[0].data = res.result.nameList;
          this.option.series[0].data = res.result.countOfUsedLicenses;
          this.option.series[1].data = res.result.countOfExpiredLicenses;
          this.option.series[2].data = res.result.countOfValidLicenses;
          this.loading = false;
          this.drawLine();
        }else {
          return;
        }
      });
      
    },
  },
};
</script>
