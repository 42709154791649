<template>
  <div>
    <div class="title">
      <div class="viewName">许可证总览</div>
    </div>
    <div class="banner">
      <div class="bannerTitle">星云互联 许可证平台</div>
      <div class="bannerLogo">NEBULALINK<br />Authorization platform</div>
      <div class="bannerDesc">
        许可证平台处理星云互联终端产品，SDK，服务等的授权工作。通过创建和管理激活码，实现授权的分发和维护。该系统支持离线授权；设备一对一绑定；权力管理等。
      </div>
      <div class="backpic"></div>
    </div>
    <div class="echartsContainer">
      <div class="areaOne">
        <viewChartsOne />
        <div class="areaOneDesc">产品——激活码数量分布</div>
      </div>
      <div class="areaTwo">
        <viewChartsTwo />
        <div class="areaTwoDesc">产品——权限数量分布</div>
      </div>
      <div class="areaThree">
        <viewChartsThree @getData="transferVal" />
        <span class="spanContainer">
          <span class="activedNum">{{ ActivatedNum }}</span>
          <span class="expiredNum">{{ ExpiredNum }}</span>
          <span class="availableNum">{{ availableNum }}</span>
        </span>

        <div class="areaThreeDesc">总产品激活码占比</div>
      </div>
    </div>
  </div>
</template>
<script>
// import myPages from "@/components/Commonpaging/Pagination";
import viewChartsOne from "@/components/viewChartsOne";
import viewChartsTwo from "@/components/viewChartsTwo";
import viewChartsThree from "@/components/viewChartsThree";
import {getProduct} from "@/api/home/home"

export default {
  name: "home",
  data() {
    return {
      total: 0, //默认数据总数
      pageSize: 10, //每页数据条数
      currentPage: 1, //默认开始页面
      show: false,
      ActivatedNum: "",
      ExpiredNum: "",
      availableNum: "",
    };
  },
  components: {
    viewChartsOne,
    viewChartsTwo,
    viewChartsThree,
  },
  methods: {
    transferVal(ActivatedNum, ExpiredNum, availableNum) {
      this.ActivatedNum = ActivatedNum;
      this.ExpiredNum = ExpiredNum;
      this.availableNum = availableNum;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: 0 solid #d5d5d5;
  border-radius: 12px;
}
.viewName {
  font-size: 18px;
  color: #4880ff;
  letter-spacing: 0;
  line-height: 60px;
  font-weight: 500;
  margin-left: 23px;
}
.banner {
  width: 100%;
  height: 346px;
  background: #233663;
  border-radius: 14px;
  margin-top: 19px;
  position: relative;
}
.bannerTitle {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  position: absolute;
  left: 160px;
  top: 50px;
}
.bannerLogo {
  font-size: 37px;
  color: #ffffff;
  font-weight: 400;
  position: absolute;
  left: 160px;
  top: 88px;
}
.bannerDesc {
  font-size: 16px;
  opacity: 0.8;
  color: #ffff;
  font-weight: 400;
  position: absolute;
  left: 160px;
  top: 192px;
  width: 472px;
  word-break: break-all;
}
.backpic {
  background: url("../../assets/pc.png");
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  right: 130px;
  width: 365px;
  height: 330px;
}
.echartsContainer {
  display: inline-flex;
  margin-top: 19px;
  width: 100%;
}
.areaOne {
  height: 497px;
  width: 33.9%;
  background: #ffffff;
  border-radius: 14px;
}
.areaOneDesc {
  font-size: 18px;
  color: #4880ff;
  letter-spacing: 0;
  margin-left: 42px;
  margin-top: 40px;
}
.areaTwo {
  height: 497px;
  width: 35.1%;
  background: #ffffff;
  border-radius: 14px;
  margin-left: 45px;
}
.areaTwoDesc {
  font-size: 18px;
  color: #4880ff;
  letter-spacing: 0;
  margin-left: 42px;
  margin-top: 40px;
}
.areaThree {
  height: 497px;
  width: 26.5%;
  background: #ffffff;
  border-radius: 14px;
  margin-left: 42px;
  position: relative;
}
.spanContainer {
  position: absolute;
  top: 300px;
}
.activedNum {
  font-size: 28px;
  color: #202224;
  letter-spacing: 1;
  position: absolute;
  top: 0px;
  left: 36px;
}
.expiredNum {
  font-size: 28px;
  color: #202224;
  letter-spacing: 1;
  position: absolute;
  top: 0px;
  left: 156px;
}
.availableNum {
  font-size: 28px;
  color: #202224;
  letter-spacing: 1;
  position: absolute;
  top: 0px;
  left: 276px;
}
.areaThreeDesc {
  font-size: 18px;
  color: #4880ff;
  letter-spacing: 0;
  margin-left: 42px;
  margin-top: 40px;
}
</style>